var config = {};

config.web = {};
config.web.server_base = 'api.smokebreak.live';
config.web.server_protocol = 'https';
config.analytics_token = 'kjdfghukgh394ghhgksdhgg34897h437gfh3fheqw98gyh349gh3g';
config.analytics_url = 'https://analytics.smokebreak.live';
config.pillowIds = [
  '62c0ed4bb1c47abbc6de2fd3',
  '62c0ed4bb1c47abbc6de2fd4',
];
config.pillowSetId = [
  '63769073722593f803aed9ed',
]

module.exports = config;
