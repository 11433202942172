import React, {Suspense, lazy, useState, useEffect} from 'react';
import { createRoot } from 'react-dom/client';
import Cookies from 'universal-cookie';
import APIUtils from './utils/APIUtils';
import NavUtils from './utils/NavUtils';
import AnalyticsUtils from './utils/AnalyticsUtils';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const Login = lazy(() => import('./components/Login/Login'));
const NavBar = lazy(() => import('./components/NavBar/NavBar'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Product = lazy(() => import('./components/Product/Product'));

const Homepage = lazy(() => import('./components/Homepage/Homepage'));
const Dealer = lazy(() => import('./components/Dealer/Dealer'));

const StonerApps = lazy(() => import('./components/Apps/Apps'));

const Account = lazy(() => import('./components/Account/Account'));
const Register = lazy(() => import('./components/Register/Register'));
const ProductList = lazy(() => import('./components/ProductList/ProductList'));
const PageNotFound = lazy(() => import('./components/PageNotFound/PageNotFound'));
const OrderComplete = lazy(() => import('./components/OrderComplete/OrderComplete'));

const Affiliate = lazy(() => import('./components/Admin/AffiliateDashboard/AffiliateDashboard'));

const AdminDashboard = lazy(() => import('./components/Admin/Dashboard/Dashboard'));
const AdminManageProducts = lazy(() => import('./components/Admin/ManageProducts/ManageProducts'));
const ManageUsers = lazy(() => import('./components/Admin/ManageUsers/ManageUsers'));

const UserActionToast = lazy(() => import('./components/UserActionToast/UserActionToast'));

import './assets/css/style.css';

Sentry.init({
	dsn: "https://4bb8bd0adfde4822821eb2118ab5c856@o4504058422427648.ingest.sentry.io/4504058432258048",
	integrations: [new BrowserTracing()],
  
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

const App = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [access, setAccess] = useState('');
	const [affiliate, setAffiliate] = useState(false);

	const [isLoginRegisterPage, setIsLoginRegisterPage] = useState(false);

	const updateState = (isLoggedIn, isLoading, access, isAffiliate=false) => {
		setIsLoading(isLoading);
		setIsLoggedIn(isLoggedIn);
		setAccess(access);
		setAffiliate(isAffiliate);
	}

	const checkAffiliate = async () => {
		const cookies = new Cookies();
		const analyticsUtils = new AnalyticsUtils();

		try {
			let userAnalyticsGUID = await analyticsUtils.getUserToken();
			analyticsUtils.sendPageHitInfo(userAnalyticsGUID);
		} catch (err) {
			console.log(err);
		}
		
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		if (typeof params.code === 'string' && params.code.length) {
			cookies.set('affiliateCode', params.code, { path: '/' });
		}
	}

	const verifyUserSession = () => {
		APIUtils.callGet('account/verify')
		.then(response => {
			if (typeof response !== 'object' || response.status !== 200 || typeof response.data !== 'object' || typeof response.data.access === 'undefined' || response.data.access === '') {
				updateState(false, false, '', false);

				// TODO: Do this in a cleaner way.
				const publicPages = ['/', '/order-complete', '/login', '/register', '/dealer', '/stoner-apps'];

				if (['/login', '/register'].includes(window.location.pathname)) {
					setIsLoginRegisterPage(true);
				}

				if (publicPages.includes(window.location.pathname) || window.location.pathname.includes('/item/')) {
					return;
				}

				return NavUtils.redirectToRoot();
			}

			updateState(true, false, response.data.access, response.data.affiliate);
		})
		.catch(err => {
			updateState(false, false, '');
		});
	}

	useEffect(() => {
		checkAffiliate();
		verifyUserSession();
	}, []);

	return (
		<Router>
			<div className="app-container">
				{!isLoginRegisterPage && (
					<NavBar 
						isloggedin={isLoggedIn} 
						access={access} 
						affiliate={affiliate} 
					/>
				)}
				<div className="app-body">
					<Suspense fallback={<div>Loading....</div>} >
						<Routes className="full-height">
							<Route 
								exact 
								path="/" 
								element={<Homepage />}
							/>
							<Route 
								exact 
								path="/stoner-apps" 
								element={<StonerApps />}
							/>
							<Route 
								exact 
								path="/login" 
								element={<Login />} 
							/>
							<Route 
								exact 
								path="/register" 
								element={<Register />} 
							/>
							<Route 
								exact 
								path="/item/:id" 
								element={<Product />} 
							/>
							<Route 
								exact 
								path="/order-complete" 
								element={<OrderComplete />} 
							/>
							<Route 
								exact 
								path="/dealer" 
								element={<Dealer />} 
							/>
							{
								(isLoggedIn) ? (
									<Route
										exact
										path="/account"
										element={<Account />}
									/>
								) : null
							}
							{
								(isLoggedIn && affiliate) ? (
									<Route
										exact
										path="/affiliate"
										element={<Affiliate />}
									/>
								) : null
							}
							{
								(isLoggedIn && access === 'employee') ? (
									<Route
										exact
										path="/admin-dashboard"
										element={<AdminDashboard />}
									/>
								) : null
							}
							{
								(isLoggedIn && access === 'employee') ? (
									<Route
										exact
										path="/admin-manage-products"
										element={<AdminManageProducts />}
									/>
								) : null
							}
							{
								(isLoggedIn && access === 'employee') ? (
									<Route
										exact
										path="/admin-manage-users"
										element={<ManageUsers />}
									/>
								) : null
							}
							{
								(!isLoading) ? (
									<Route
										element={()=><PageNotFound />} 
									/>
								) : null
							}
						</Routes>
					</Suspense>
				</div>
				{!isLoginRegisterPage && (
					<Footer />
				)}
			</div>
		</Router>
	);
}

// TODO: switch to use createRoot from react-dom/client once upgraded
const root = createRoot(document.getElementById('root'));
root.render(<App />);