import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

class AnalyticsUtils {
    constructor() {
        const config = require('../config/config.js');
        
        this.token = config.analytics_token;
        this.url = config.analytics_url;
    }

    async getUserToken() {
        const cookies = new Cookies();
        let userAnalyticsGUID = cookies.get('userAnalyticsGUID');
	
        if (userAnalyticsGUID === undefined) {
            userAnalyticsGUID = uuidv4();
            cookies.set('userAnalyticsGUID', userAnalyticsGUID);

            await this.sendSessionInfo(userAnalyticsGUID);
        }

        return userAnalyticsGUID;
    }

    sendSessionInfo(guid) {
        return new Promise((resolve, reject) => {
            const headers = {
                'Content-Type': 'application/json',
                'token': this.token
            };

            const requestOptions = {
                method: 'POST',
                headers: headers,
                mode: 'cors',
                body: JSON.stringify({ guid: guid })
            };

            fetch(`${this.url}/session`, requestOptions)
                .then(response => response.json())
                .then(data => resolve(data));
        });
    }

    sendPageHitInfo(guid) {
        const headers = {
            'Content-Type': 'application/json',
            'token': this.token
        };

        const page = window.location.pathname;

        const requestOptions = {
            method: 'POST',
            headers: headers,
            mode: 'cors',
            body: JSON.stringify({ guid: guid, page: page })
        };

        fetch(`${this.url}/page_hits`, requestOptions)
            .then(response => response.json())
            .then(data => console.log(data));
    }

    sendSale(guid, data) {
        const headers = {
            'Content-Type': 'application/json',
            'token': this.token
        };

        const requestOptions = {
            method: 'POST',
            headers: headers,
            mode: 'cors',
            body: JSON.stringify({ guid: guid, sale_data: data })
        };

        fetch(`${this.url}/sales`, requestOptions)
            .then(response => response.json())
            .then(data => console.log(data));
    }
}

export default AnalyticsUtils;